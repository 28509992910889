<template scoped>
  <div>
    <el-form-item
      :rules="rules.name"
      label="Name"
      prop="mobile.azureNotificationHub.name"
      :style="{ marginTop: '10px' }"
    >
      <el-input
        v-model="provider.mobile.azureNotificationHub.name"
        placeholder=""
      ></el-input>
    </el-form-item>

    <el-form-item
      :rules="rules.connectionString"
      label="Connection String"
      prop="mobile.azureNotificationHub.connectionString"
      :style="{ marginTop: '10px' }"
    >
      <el-input
        v-model="provider.mobile.azureNotificationHub.connectionString"
        placeholder=""
      ></el-input>
    </el-form-item>
  </div>
</template>
      
<script scoped>
export default {
  props: {
    provider: Object,
  },
  methods: {
    validator_name: function (rule, value, callback) {
      if (!value) {
        callback(new Error("the name should not be empty"));
      } else {
        callback();
      }
    },
    validator_connectionString: function (rule, value, callback) {
      if (!value) {
        callback(new Error("the connectionString should not be empty"));
      } else {
        callback();
      }
    },
  },
  data: function () {
    return {
      rules: {
        name: [{ validator: this.validator_name, trigger: "blur" }],
        connectionString: [
          { validator: this.validator_connectionString, trigger: "blur" },
        ],
      },
    };
  },
};
</script>    